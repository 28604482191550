<script>
export default {
  name: 'notifyMixin',
  methods: {
    notifySuccess(message) {
      this.$bvToast.toast(message, {
        title: 'Sucesso',
        variant: 'success',
        solid: true,
      });
    },
    notifyError(message) {
      this.$bvToast.toast(message, {
        title: 'Erro',
        variant: 'danger',
        solid: true,
      });
    },
    notifyWarning(message) {
      this.$bvToast.toast(message, {
        title: 'Atenção',
        variant: 'warning',
        solid: true,
      });
    },
    notifyInfo(message) {
      this.$bvToast.toast(message, {
        title: 'Info',
        variant: 'info',
        solid: true,
      });
    },
  },
};
</script>
