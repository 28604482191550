<template>
  <div class="position-relative">
    <div class="label-content" v-if="label.length > 0">
      <label for="" :style="{ backgroundColor: disabled ? '#fafafa !important' : '#fff' }">
        {{ label }}
      </label>
    </div>
    <money
      v-model="money"
      :disabled="disabled"
      :class="{ 'form-group--error': error }"
    />
    <span class="f-sm fontPoppins fw-500" v-if="error">{{ errorMessage }}</span>
    <div class="icon">
      <ErrorInputIcon v-if="error" />
    </div>
  </div>
</template>

<script>
import ErrorInputIcon from '@/components/icons/errorInputIcon.vue';

export default {
  name: 'InputMoney',
  components: {
    ErrorInputIcon,
  },
  data() {
    return {
      money: this.value,
    };
  },
  watch: {
    money() {
      this.$emit('input', this.money);
    },
    value() {
      this.money = this.value;
    },
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.label-content {
  position: absolute;
  top: -13px;
  left: 13px;

  label {
    font-family: 'Popins', sans-serif;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 1px;
    background: #fff;
    display: inline !important;
    padding: 5px 10px;
    color: #7256A4 !important;
  }
}

input {
  font-size: 14px !important;
}

.icon {
  position: absolute;
  right: 10px;
  top: 10px;
}
span {
  left: 5px;
  bottom: -20px;
  color: #F31155;
  letter-spacing: 0.5px;
}
</style>
