import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: () => import('../views/Layout.vue'),
    name: 'Home',
    children: [
      {
        path: 'usuarios',
        name: 'UsersList',
        component: () => import('@/pages/users/users-list.vue'),
        meta: {
          title: 'Usuários',
          subtitle: 'Cadastre e gerencie os usuários da platforma.',
        },
      },
      {
        path: 'usuarios/:id',
        name: 'UserDetails',
        props: true,
        component: () => import('@/pages/users/user-show.vue'),
      },
      {
        path: 'planos',
        name: 'PlansList',
        component: () => import('@/pages/plans/plans-list.vue'),
        meta: {
          title: 'Planos',
          subtitle: 'Cadastre e gerencie os planos da plataforma.',
        },
      },
    ],
  },
  {
    path: '/login',
    component: () => import('@/pages/auth/layout.vue'),
    children: [
      {
        path: '/',
        name: 'AuthLogin',
        component: () => import('@/pages/auth/login.vue'),
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
