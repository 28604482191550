<template>
  <div class="form-group">
    <div
      class="label-content"
      v-if="label.length > 0"
      :class="{ 'primary': variant === 'primary', 'secondary': variant === 'secondary' }"
    >
      <label :for="name" class="w-100">
        {{ label }} <span v-if="required">(Opcional)</span>
      </label>
    </div>
    <b-form-datepicker
      id="input-date"
      v-model="date"
      locale="pt-BR"
      reset-button
      label-reset-button="Limpar"
      label-no-date-selected="dd/mm/aaaa"
      aria-controls="example-input"
      :class="{ 'form-group--error': error }"
      @input="onContext"
    >
      <template #button-content>
        <CalendarIcon />
      </template>
    </b-form-datepicker>
    <span class="f-sm fontPoppins fw-500" v-if="error">{{ errorMessage }}</span>
    <div class="icon">
      <ErrorInputIcon v-if="error" />
    </div>
  </div>
</template>
<script>
import CalendarIcon from '@/components/icons/calendarIcon.vue';
import ErrorInputIcon from '@/components/icons/errorInputIcon.vue';

export default {
  name: 'InputDate',
  components: {
    CalendarIcon,
    ErrorInputIcon,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'primary',
    },
    required: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      date: this.value,
    };
  },
  watch: {
    value() {
      this.date = this.value;
    },
  },
  methods: {
    onContext(ctx) {
      this.$emit('input', ctx);
    },
  },
};
</script>

<style lang="scss" scoped>
.b-form-datepicker {
  flex-direction: row-reverse !important;
  padding: 8px !important;
  border: 1px solid #E9EDF7;
  border-radius: 8px;
}
.form-group {
  position: relative;
}
.label-content {
  position: absolute;
  top: -13px;
  left: 13px;
  z-index: 9;

  &.primary {
    color: #7256A4 !important;
  }

  &.secondary {
    color: #6E6E6E !important;
  }
}

label {
  font-family: 'Popins', sans-serif;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 1px;
  background: #fff;
  display: inline !important;
  padding: 5px 10px;

  span {
    color: #12A4F5 !important;
    font-family: 'Popins', sans-serif;
    font-weight: 600;
  }
}
.icon {
  position: absolute;
  right: 50px;
  top: 14px;
}
span {
    left: 5px;
    bottom: -20px;
    color: #F31155;
    letter-spacing: 0.5px;
  }
</style>y
