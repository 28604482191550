<template>
  <div class="form-group">
    <div
      class="label-content"
      :class="{ 'primary': variant === 'primary', 'secondary': variant === 'secondary' }"
    >
      <label :for="name" class="w-100" v-if="label">
        {{ label }} <span v-if="!required">(Opcional)</span>
      </label>
    </div>
    <b-form-select
      :options="options"
      class="select"
      :value="value"
      @change="$emit('change', $event)"
    />
    <span class="f-sm fontPoppins fw-500" v-if="error">{{ errorMessage }}</span>
    <div class="icon">
      <ErrorInputIcon v-if="error" />
    </div>
  </div>
</template>

<script>
import ErrorInputIcon from '@/components/icons/errorInputIcon.vue';

export default {
  name: 'InputLabel',
  components: {
    ErrorInputIcon,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'primary',
    },
    required: {
      type: Boolean,
      default: true,
    },
    mask: {
      required: false,
      default: '',
    },
    errorMessage: {
      type: String,
      default: 'Campo obrigatório',
    },
    error: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    value: {
      type: [String, Number],
      required: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    name: {
      type: String,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  position: relative;
}
.label-content {
  position: absolute;
  top: -13px;
  left: 13px;

  &.primary {
    color: #7256A4 !important;
  }

  &.secondary {
    color: #6E6E6E !important;
  }
}
.select {
  padding-left: 18px !important;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  color: #323232;
  font-size: 14px;

  &::placeholder {
    color: #6e6e6e !important;
    font-size: 14px !important;
    font-family: 'Inter', sans-serif !important;
    font-weight: 400 !important;
  }
}
label {
  font-family: 'Popins', sans-serif;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 1px;
  background: #fff;
  display: inline !important;
  padding: 5px 10px;

  span {
    color: #12A4F5 !important;
    font-family: 'Popins', sans-serif;
    font-weight: 600;
  }
}
.icon {
  position: absolute;
  right: 10px;
  top: 10px;
}
span {
    left: 5px;
    bottom: -20px;
    color: #F31155;
    letter-spacing: 0.5px;
  }
</style>
