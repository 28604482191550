import http from '../../http';

const state = {
  provinces: [],
};

const getProvinces = async () => {
  const { data } = await http.get('location/provinces');
  return data;
};

const getters = {
  // eslint-disable-next-line no-shadow
  provinces: (state) => state.provinces,
};

const mutations = {
  // eslint-disable-next-line no-shadow
  setProvinces(state, provinces) {
    state.provinces = provinces;
  },
};

const actions = {
  async getProvinces({ commit }) {
    const provinces = await getProvinces();
    commit('setProvinces', provinces);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
