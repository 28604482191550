import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import ProvinceModule from './modules/provinceModule';
import UserModule from './modules/userModule';

Vue.use(Vuex);

const persistOptions = {
  key: 'vendedoras_sucesso',
  storage: window.sessionStorage,
  paths: ['cart', 'user'],
};

const store = new Vuex.Store({
  modules: {
    provinces: ProvinceModule,
    user: UserModule,
  },
  plugins: [createPersistedState(persistOptions)],
});

export default store;
