import Vue from 'vue';
import Vuelidate from 'vuelidate';
import PrettyCheckbox from 'pretty-checkbox-vue';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import VueTheMask from 'vue-the-mask';
import VueMoment from 'vue-moment';
import Money from 'v-money';
import LvColorPicker from 'lightvue/color-picker';
import NotifyMixin from '@/mixins/notify/notifyMixin.vue';
import ModalDefault from '@/components/notifications/modal.vue';
import InputLabel from '@/components/form/inputs/inputLabel.vue';
import ModalConfirm from '@/components/notifications/modal-confirm.vue';
import MultipleSelect from '@/components/form/select/multipleSelect.vue';
import SelectInput from '@/components/form/select/selectInput.vue';
import InputMoney from '@/components/form/inputs/inputMoney.vue';
import InputDate from '@/components/form/inputs/inputDate.vue';
import InputIcon from '@/components/form/inputs/inputIcon.vue';
import VCalendar from 'v-calendar';
import Vue2Filters from 'vue2-filters';
import excel from 'vue-excel-export';
import lodash from 'lodash';
import moment from 'moment';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import filters from './filters';
import 'moment/locale/pt-br';

moment.locale('pt-br');

Vue.config.productionTip = false;

const Vue2FiltersConfig = {
  capitalize: {
    onlyFirstLetter: false,
  },
  number: {
    format: '0',
    thousandsSeparator: ',',
    decimalSeparator: '.',
  },
  currency: {
    symbol: 'R$ ',
    decimalDigits: 2,
    thousandsSeparator: '.',
    decimalSeparator: ',',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    showPlusSign: false,
  },
  pluralize: {
    includeNumber: false,
  },
  ordinal: {
    includeNumber: false,
  },
};

const moneyConfigs = {
  decimal: ',',
  thousands: '.',
  prefix: 'R$ ',
  suffix: '',
  precision: 2,
  masked: false,
};

Vue.component('modal-default', ModalDefault);
Vue.component('InputLabel', InputLabel);
Vue.component('MultipleSelect', MultipleSelect);
Vue.component('LvColorPicker', LvColorPicker);
Vue.component('InputMoney', InputMoney);
Vue.component('SelectInput', SelectInput);
Vue.component('InputDate', InputDate);
Vue.component('InputIcon', InputIcon);
Vue.component('ModalConfirm', ModalConfirm);

Vue.use(Money, moneyConfigs);
Vue.use(VCalendar, {
  componentPrefix: 'vc',
  screens: {
    tablet: 768,
    laptop: '100%',
    desktop: '100%',
  },
});

Vue.use(Vuelidate);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueTheMask);
Vue.use(PrettyCheckbox);
Vue.mixin(NotifyMixin);
Vue.use(Vue2Filters, Vue2FiltersConfig);
Vue.use(VueMoment, {
  moment,
});
Vue.use(excel);
Vue.use(lodash);

Object.keys(filters).forEach((filter) => {
  Vue.filter(filter, filters[filter]);
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
