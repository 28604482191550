export default {
  initials: (name) => {
    if (!name) {
      return '';
    }
    const nameArray = name.split(' ');
    let firstLetter = '';
    if (nameArray.length > 1) {
      firstLetter = nameArray[0].charAt(0) + nameArray[1].charAt(0);
    } else {
      firstLetter = nameArray[0].charAt(0);
    }

    return firstLetter;
  },
  formatMoney: (value) => {
    if (typeof value !== 'number') {
      return value;
    }

    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

    return formatter.format(value);
  },
};
