<!-- eslint-disable max-len -->
<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 1.66675L19.1667 17.5001H0.833374L10 1.66675ZM10 4.99175L3.72504 15.8334H16.275L10 4.99175Z" fill="#F53770"/>
    <path d="M9.16663 13.3333H10.8333V14.9999H9.16663V13.3333Z" fill="#F53770"/>
    <path d="M9.16663 8.33325H10.8333V11.6666H9.16663V8.33325Z" fill="#F53770"/>
  </svg>
</template>

<script>
export default {
  name: 'ErrorInputIcon',
};
</script>
